<template>
  <main class="o-row">
    <div class="c-row" v-for="ed in eventDisciplines" :key="ed.id">
      <h3 class="c-title c-title--event u-push-1-of-5 u-4-of-5 u-padding-left-small">{{ed.name}}</h3>

      <div class="o-layout u-margin-bottom-medium">
        <h4 class="c-title c-title--session o-layout__item u-push-none u-1-of-1 u-push-1-of-5-at-tiny
                u-4-of-5-at-tiny u-push-none-at-small u-1-of-5-at-small">
          {{$t('sessions.active')}}
        </h4>
        <div class="c-button--group c-button--group__huge o-layout__item u-push-none u-1-of-1 u-push-1-of-5-at-tiny
                u-4-of-5-at-tiny u-push-none-at-small u-4-of-6-at-small">
          <template v-for="session in getSessions(ed.id)">
            <router-link :key="session.id"
              class="c-button c-button--huge c-button--primary"
              :to="{ name: 'supervisor.panels', params: { sessionId: session.id}}">
              {{session.name}}
            </router-link>
          </template>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import filter from 'lodash/filter';
  import sortBy from 'lodash/sortBy';

  export default {
    name: "supervisor-sessions",
    computed: {
      eventDisciplines: function () {
        return filter(this.$store.state.eventDisciplines.items, ed => this.hasSessions(ed.id));
      },
      sessions: function () {
        const tmp = filter(this.$store.state.sessions.items, function (session) {
          return session.status === 'active'
        });
        return sortBy(tmp, 'index');
      },
    },
    methods: {
      hasSessions: function(edId) {
        return this.getSessions(edId).length > 0;
      },
      getSessions: function (edId) {
        return filter(this.sessions, item => {
          return item.eventDisciplineId === edId;
        });
      },
    }
  }
</script>

<style scoped>

</style>
