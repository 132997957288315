<template>
  <div class="c-sidebar">
    <div :class="{'c-sidebar__active-group': ! $route.fullPath.includes('results')}">
      <router-link
        class="c-sidebar__item c-sidebar__item-with-childs c-sidebar__item-open"
        :class="{'c-sidebar__item-open': ! $route.fullPath.includes('results')}"
        exact :to="{ name: 'supervisor.panels'}">
        {{ $t('panels') }}
      </router-link>

      <template v-if="! $route.fullPath.includes('results')">
        <router-link v-for="panel in panels" :key="panel.id" class="c-sidebar__item"
                     exact :to="{ name: 'supervisor.panel', params: {panelId: panel.id}}">
          {{ panelLabel(panel) }}
        </router-link>
      </template>
    </div>

    <div :class="{'c-sidebar__active-group': $route.fullPath.includes('results')}">
      <router-link
        class="c-sidebar__item c-sidebar__item-with-childs c-sidebar__item-open"
        :class="{'c-sidebar__item-open': $route.fullPath.includes('results')}"
        exact :to="{ name: 'supervisor.results'}">
        {{ $t('results') }}
      </router-link>

      <template v-if="$route.fullPath.includes('results')">
        <router-link v-for="sessionCategory in session.categories" :key="sessionCategory.categoryId"
                     class="c-sidebar__item"
                     :to="{ name: 'supervisor.category', params: {categoryId: sessionCategory.categoryId}}">
          {{ categoryLabel(sessionCategory) }}
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
import filter from 'lodash/filter';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';

import sessionLib from 'client/lib/session'

export default {
  props: ['sessionId'],
  name: 'supervisor-menu',
  computed: {
    discipline: function () {
      return this.$store.state.eventDiscipline.discipline;
    },
    session: function () {
      return find(this.$store.state.sessions.items, item => {
        return item.id === this.sessionId;
      });
    },
    categories: function () {
      return this.$store.state.categories.items;
    },
    panels: function () {
      return sortBy(filter(this.$store.state.panels.items, item => {
        return item.sessionId === this.sessionId;
      }), ['set', 'index']);
    },
    rotationType: function() {
      return sessionLib.getSessionRotationType(this.session.id)
    }
  },
  methods: {
    panelLabel: function (panel) {
      let label = '';

      if (this.rotationType === 'rotation' && this.session.sets > 1) {
        label = this.$t('set') + ' ' + panel.set + ' ';
      }

      if (panel.exerciseTypeId) {
        label += this.$t('exercise.type.' + panel.exerciseTypeId);
      } else {
        label += this.$t('panel') + ' ' + (panel.index + 1);
      }

      return label;
    },
    categoryLabel: function (sessionCategory) {
      const category = find(this.categories, item => {
        return item.id === sessionCategory.categoryId;
      });
      return category.name;
    },
  },
};
</script>
