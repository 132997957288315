<template>
  <div class="o-wrapper o-wrapper--huge c-wrapper">
    <vue-progress-bar></vue-progress-bar>
    <div class="c-wrapper--min-100vh">
      <header class="c-header o-wrapper o-wrapper--huge js-header" :class="{'is-shrinked': shrinked}">
        <router-link :to="{ name: 'supervisor.sessions'}" class="c-logo__link">
          <div class="c-logo"></div>
        </router-link>
        <h1 class="c-header__title u-push-1-of-5-at-medium u-3-of-4-at-medium"
            :title="headerTitle()">{{headerTitle()}}</h1>

        <breadcrump/>
      </header>
      <div class="c-main-wrapper o-layout o-layout--main">
        <router-view name="menu" class="c-sidebar o-layout__item"></router-view>
        <div class="c-main c-main--after-sidebar o-layout__item">
          <router-view :key="$route.fullPath"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from "@/store";

  export default {
    name: 'supervisor-wrapper',
    data: function() {
      return {
        shrinked: false,
      }
    },
    components: {
      breadcrump: require('./breadcrump.vue').default,
    },
    computed: {
      event: function() {
        return store.state.events.current
      },
      user: function () {
        return this.$store.state.user.instance;
      }
    },
    methods: {
      headerTitle: function() {
        return this.event ? this.event.title : this.user.name ? this.user.name : this.user.username;
      }
    }
  };
</script>

<style scoped>

</style>
