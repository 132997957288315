<template>
  <div>
    <h2 class="c-title c-title--page">{{$t('panels')}}</h2>
    <main class="o-row">
      <div class="c-row" v-for="set in getSets()" :key="set">
        <h3 class="c-title c-title--event u-padding-left-small">
          {{isPerSet() ? $t('set') + ' ' + set : ''}}
        </h3>
        <div class="o-layout u-margin-bottom-medium">
          <div class="c-button--group o-layout__item u-1-of-1">
            <router-link v-for="panel in getPanels(set)" :key="panel.id"
              class="c-button c-button--large c-button--primary"
              :to="{ name: 'supervisor.panel', params: { panelId: panel.id}}">
              {{$t('panel')}} {{getPanelIndex(panel)}}
              <span class="c-button__extra-label" v-if="panel.exerciseTypeId">
                {{$t('exercise.type.'+ panel.exerciseTypeId)}}
              </span>
            </router-link>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
  import filter from 'lodash/filter';
  import find from 'lodash/find';
  import sortBy from 'lodash/sortBy';
  import sessionLib from "@/lib/session";

  export default {
    name: "supervisor-panels",
    computed: {
      discipline: function() {
        return this.$store.state.eventDiscipline.discipline;
      },
      session: function() {
        return find(this.$store.state.sessions.items, item => {
          return item.id === this.$route.params.sessionId;
        });
      },
      panels: function() {
        return this.$store.state.panels.items;
      },
      rotationType: function() {
        return sessionLib.getSessionRotationType(this.session.id)
      }
    },
    methods: {
      getSets: function() {
        return this.isPerSet() ? this.session.sets : 1
      },
      isPerSet: function() {
        return this.rotationType === 'rotation'
      },
      getPanels: function (set) {
        return sortBy(filter(this.panels, item => {
          return item.sessionId === this.session.id && (!this.isPerSet() || item.set === set);
        }), ['set', 'index']);
      },
      getPanelIndex: function(panel) {
        return this.isPerSet() ? panel.index+1 : panel.set;
      },
      panelLabel: function(panel) {
        let label = '';

        if (this.rotationType === 'rotation' && this.session.sets > 1) {
          label = this.$t('set') + ' ' + panel.set + ' ';
        }

        if (panel.exerciseTypeId) {
          label += this.$t('exercise.type.' + panel.exerciseTypeId);
        } else {
          label += this.$t('panel') + ' ' + (panel.index + 1);
        }

        return label;
      },
    },
  }
</script>

<style scoped>
</style>
